import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {Staff} from "../components/staff"
const TeamPage = ({ data, path, pageContext }) => {
  const {
    meta_title, 
    meta_description, 
    about_title,
    offices_title,
    offices,
    staff_title,
    staff,
    _meta,
    
  } = data.prismic.allHomes.edges[0].node
  return(
    <Layout 
      offices={offices} 
      path={path} 
      staffTitle={staff_title} 
      officesTitle={offices_title} 
      title={meta_title}
      aboutTitle={about_title}
      darkBackground={true}
      lang={_meta.lang}
      pageLanguage={pageContext.siteLanguage}
      uid={'team'}
    >
      <SEO title={meta_title} description={meta_description} />
      <section>
        <Staff staffTitle={staff_title} staff={staff} email officesTitle={offices_title} />
      </section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query TeamPage($locale: String!) {
    prismic {
      allHomes(lang: $locale) {
        edges {
          node {
            meta_title
            meta_description
            about_title
            offices_title
            offices {
              postal_code
              eMail
              address
              telephone
              city
              country
            }
            _meta {
                  lang
                }
            staff_title
            staff {
              eMail_title
              eMail
              practice_area_title
              practice_area
              name
              membership_title
              membership
              languages_title
              languages
              job_title
              education_title
              education
              img
              video {
                ... on PRISMIC__FileLink {
                  _linkType
                  url
                  size
                }
              }
              mp4 {
                ... on PRISMIC__FileLink {
                  _linkType
                  url
                  size
                }
              }
            }
          }
        }
      }
    }
  }
`

export default TeamPage
